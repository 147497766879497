.container {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 56px;
  padding: 0 28px;
  background: linear-gradient(180deg, #545BE8 0%, #272DB0 100%);
  display: flex;
  align-items: center;
}

.buttonsWrapper {
  display: flex;
  color: white;
  font-size: large;
  gap: 20px;
  margin-left: 20px;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    font: inherit;
    color: white;

    &:after {
      backface-visibility: hidden;
      border: 1px solid rgba(#fb2b89, 0);
      bottom: 0;
      content: " ";
      display: block;
      margin: 0 auto;
      position: relative;
      transition: all 280ms ease-in-out;
      width: 0;
    }

    &:hover:after {
      backface-visibility: hidden;
      border-color: #fc8846;
      transition: width 350ms ease-in-out;
      width: 100%;
    }

    &.active:after {
      backface-visibility: hidden;
      border-color: #fc8846;
      transition: width 350ms ease-in-out;
      width: 100%;
    }
  }
}
